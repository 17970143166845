import React,{Component} from "react";

import { TweenMax ,Power4} from "gsap/all";

class ComingSoon extends Component{

	componentDidMount() {
		//TweenMax.fromTo('#cs-comming', 2, {drawSVG:1}, {drawSVG:"100%"},);
        TweenMax.fromTo('#soonClipPath', 2,{y:400},{y:-150,ease: Power4.inout})
        this.createLineTween(document.getElementById('cs-comming'));
    }
    
    createLineTween(svg) { 
        var pathObject = {length:0, pathLength:svg.getTotalLength()}; 
        var tween = TweenMax.to(pathObject, 16, {length:pathObject.pathLength, onUpdate:this.drawLine, onUpdateParams:[pathObject, svg], immediateRender:true});
        return tween;
    }
     
     drawLine(obj, svg) {
       svg.style.strokeDasharray = [obj.length, obj.pathLength].join(' ');
      }

	render(){
		return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 801.7 330"  className={this.props.className}>
            <g>
                <path id="cs-comming" fill="none" stroke="#000000" strokeMiterlimit="10" d="M69,30c-11,0-19.5,4.1-25.6,12.4c-6,8.3-9.1,19.8-9.1,34.6
                    c0,30.7,11.5,46.1,34.6,46.1c9.7,0,21.4-2.4,35.2-7.3v24.5c-11.3,4.7-24,7.1-37.9,7.1c-20.1,0-35.4-6.1-46-18.2
                    C9.7,117,4.3,99.6,4.3,76.8c0-14.3,2.6-26.9,7.8-37.7c5.2-10.8,12.7-19,22.5-24.8C44.4,8.6,55.9,5.7,69,5.7
                    c13.4,0,26.8,3.2,40.4,9.7L100,39.2c-5.2-2.5-10.3-4.6-15.6-6.4C79.2,30.9,74.1,30,69,30z M258.7,76.4c0,22.8-5.7,40.4-17,52.6
                    c-11.3,12.3-27.5,18.4-48.7,18.4c-21.1,0-37.3-6.1-48.7-18.4s-17-29.9-17-52.8c0-22.9,5.7-40.5,17-52.6
                    C155.8,11.6,172,5.5,193.2,5.5c21.2,0,37.4,6.1,48.6,18.3C253.1,36,258.7,53.5,258.7,76.4z M158.1,76.4c0,15.4,2.9,27,8.8,34.8
                    c5.8,7.8,14.6,11.7,26.2,11.7c23.3,0,35-15.5,35-46.5c0-31.1-11.6-46.6-34.8-46.6c-11.6,0-20.4,3.9-26.3,11.7
                    C161,49.4,158.1,61,158.1,76.4z M345.6,145.6L312.4,37.4h-0.8c1.2,22,1.8,36.7,1.8,44v64.1h-26.1V7.7H327l32.6,105.4h0.6L394.8,7.7
                    h39.8v137.9h-27.3V80.3c0-3.1,0-6.6,0.1-10.7c0.1-4,0.5-14.7,1.3-32.1H408l-35.6,108H345.6z M469.3,145.6V7.7h29.2v137.9H469.3z
                     M655.6,145.6h-37.2l-60-104.3h-0.8c1.2,18.4,1.8,31.6,1.8,39.4v64.9h-26.1V7.7h36.9L630,110.9h0.7c-0.9-17.9-1.4-30.6-1.4-38V7.7
                    h26.3V145.6z M742.6,68.1h54.7v71.5c-8.9,2.9-17.2,4.9-25,6.1c-7.8,1.2-15.8,1.7-24,1.7c-20.8,0-36.7-6.1-47.7-18.3
                    c-11-12.2-16.5-29.8-16.5-52.7c0-22.3,6.4-39.6,19.1-52.1c12.7-12.4,30.4-18.7,53-18.7c14.1,0,27.8,2.8,40.9,8.5l-9.7,23.4
                    c-10.1-5-20.5-7.5-31.4-7.5c-12.6,0-22.8,4.2-30.4,12.7c-7.6,8.5-11.4,19.9-11.4,34.2c0,15,3.1,26.4,9.2,34.3
                    c6.1,7.9,15,11.8,26.7,11.8c6.1,0,12.3-0.6,18.6-1.9V92.5h-26.1V68.1z"/>
                <g>
                <defs>
<rect id="soonClipPath" x="109.4" y="322.5" width="579.3" height="152"/>
</defs>
<clipPath id="soonMask">
<use xlinkHref="#soonClipPath"  overflow="visible"/>
</clipPath>
<path clipPath="url(#soonMask)" d="M220.2,278.7c0,12.2-4.4,21.8-13.2,28.8c-8.8,7-21,10.5-36.6,10.5c-14.4,0-27.2-2.7-38.3-8.1
v-26.6c9.1,4.1,16.8,6.9,23.2,8.6c6.3,1.7,12.1,2.5,17.3,2.5c6.3,0,11.1-1.2,14.5-3.6c3.4-2.4,5-6,5-10.7c0-2.6-0.7-5-2.2-7.1
s-3.7-4.1-6.5-6c-2.9-1.9-8.7-5-17.5-9.2c-8.3-3.9-14.5-7.6-18.6-11.2c-4.1-3.6-7.4-7.7-9.9-12.5c-2.5-4.7-3.7-10.3-3.7-16.6
c0-12,4.1-21.4,12.2-28.2c8.1-6.8,19.3-10.3,33.6-10.3c7,0,13.7,0.8,20.1,2.5c6.4,1.7,13,4,20,7l-9.2,22.3c-7.2-3-13.2-5-17.9-6.2
c-4.7-1.2-9.4-1.8-13.9-1.8c-5.4,0-9.6,1.3-12.5,3.8c-2.9,2.5-4.3,5.8-4.3,9.9c0,2.5,0.6,4.7,1.8,6.6c1.2,1.9,3,3.7,5.6,5.5
c2.6,1.8,8.6,4.9,18.2,9.5c12.6,6,21.3,12.1,26,18.2C217.9,262.4,220.2,269.9,220.2,278.7z M367.4,248.4c0,22.4-5.5,39.6-16.6,51.6
s-27,18-47.7,18s-36.6-6-47.7-18c-11.1-12-16.6-29.3-16.6-51.8c0-22.5,5.6-39.7,16.7-51.5c11.1-11.9,27.1-17.8,47.8-17.8
c20.8,0,36.6,6,47.6,17.9C361.9,208.8,367.4,226,367.4,248.4z M268.8,248.4c0,15.1,2.9,26.5,8.6,34.1c5.7,7.6,14.3,11.5,25.7,11.5
c22.9,0,34.3-15.2,34.3-45.6c0-30.4-11.4-45.7-34.1-45.7c-11.4,0-20,3.8-25.8,11.5C271.6,222,268.8,233.3,268.8,248.4z M518,248.4
c0,22.4-5.5,39.6-16.6,51.6c-11.1,12-27,18-47.7,18c-20.7,0-36.6-6-47.7-18c-11.1-12-16.6-29.3-16.6-51.8
c0-22.5,5.6-39.7,16.7-51.5c11.1-11.9,27.1-17.8,47.8-17.8c20.8,0,36.6,6,47.6,17.9C512.5,208.8,518,226,518,248.4z M419.4,248.4
c0,15.1,2.9,26.5,8.6,34.1c5.7,7.6,14.3,11.5,25.7,11.5c22.9,0,34.3-15.2,34.3-45.6c0-30.4-11.4-45.7-34.1-45.7
c-11.4,0-20,3.8-25.8,11.5C422.3,222,419.4,233.3,419.4,248.4z M665.9,316.2h-36.4L570.7,214h-0.8c1.2,18.1,1.8,30.9,1.8,38.6v63.6
H546V181.1h36.1l58.7,101.2h0.6c-0.9-17.6-1.4-30-1.4-37.2v-64h25.8V316.2z"/>
                </g>
            </g>
            </svg>
            );
	}
}
export default ComingSoon;
