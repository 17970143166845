import React,{Component} from "react";

import { TweenMax ,Linear} from "gsap/all";

class Waiting extends Component{

	componentDidMount() {
		TweenMax.to('#waitingSVG', 30, {rotation:360,repeat: -1, ease:Linear.easeNone});
		TweenMax.fromTo('#waitingSVG', 2,{x:50,y:-50},{x:-50,y:50})
	}

	render(){
		return (<svg id="waitingSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.3 107.4"  className={this.props.className}>
		<path  fill="#FF3333" d="M46.6,6.3l-0.4,0.1L45.4,1l-1.8,0.3l-0.1-0.4l4.1-0.6l0.1,0.4L45.8,1L46.6,6.3z M57.7,5.9l-0.4,0l0.1-2.8
			L54,2.9l-0.1,2.8l-0.4,0L53.8,0l0.4,0L54,2.5l3.3,0.1l0.1-2.5l0.4,0L57.7,5.9z M63.5,6.8l1.2-5.6L65,1.3l-1.2,5.6L63.5,6.8z M73,8.2
			c-0.2,0.4-0.5,0.7-0.9,0.9s-0.9,0.1-1.5-0.1c-0.7-0.3-1.1-0.5-1.4-0.8l0.1-0.4c0.3,0.3,0.8,0.6,1.4,0.8c0.4,0.2,0.8,0.2,1.2,0.1
			s0.6-0.3,0.7-0.6c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2-0.1-0.3-0.3-0.5c-0.1-0.2-0.4-0.4-0.7-0.7c-0.5-0.4-0.8-0.7-0.9-1
			c-0.1-0.3-0.1-0.6,0-1c0.2-0.4,0.4-0.7,0.8-0.8s0.9-0.1,1.4,0.1c0.5,0.2,0.9,0.5,1.3,0.8L74,4.6c-0.4-0.4-0.8-0.6-1.2-0.8
			c-0.4-0.2-0.7-0.2-1-0.1c-0.3,0.1-0.5,0.3-0.6,0.6c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.2,0.1,0.3,0.2,0.5c0.1,0.2,0.4,0.4,0.7,0.7
			c0.4,0.3,0.6,0.6,0.8,0.8c0.2,0.2,0.3,0.4,0.3,0.6C73.1,7.7,73.1,7.9,73,8.2z M87.9,14.5c-0.3,0.4-0.8,0.6-1.2,0.6s-1-0.2-1.5-0.7
			l-0.7-0.6L83,15.7l-0.3-0.3l3.6-4.4l1.1,0.9C88.4,12.8,88.6,13.7,87.9,14.5z M84.7,13.6l0.6,0.5c0.5,0.4,0.9,0.6,1.3,0.6
			c0.4,0,0.7-0.2,1-0.5c0.3-0.3,0.4-0.7,0.3-1c-0.1-0.3-0.3-0.7-0.8-1l-0.7-0.6L84.7,13.6z M92.5,22.3L91,20.5L89,21.2l-0.3-0.3l5.9-2
			l0.2,0.2l-2.9,5.4l-0.3-0.3L92.5,22.3z M91.4,20.4l1.3,1.6l1.1-1.9c0.1-0.2,0.2-0.4,0.4-0.6c-0.2,0.1-0.5,0.2-0.7,0.3L91.4,20.4z
			M98.2,29.3l1,1.8L97,32.4c-0.5-0.4-0.9-0.9-1.2-1.5c-0.4-0.8-0.5-1.5-0.3-2.2c0.2-0.7,0.7-1.2,1.5-1.7c0.5-0.3,1-0.4,1.5-0.4
			s1,0.1,1.4,0.4c0.4,0.3,0.8,0.7,1.1,1.2c0.3,0.5,0.5,1.1,0.5,1.6l-0.4,0c0-0.5-0.2-1-0.4-1.5c-0.4-0.7-0.9-1.1-1.5-1.2
			c-0.6-0.2-1.3,0-2,0.3c-0.8,0.4-1.2,0.9-1.4,1.5c-0.2,0.6-0.1,1.2,0.3,1.9c0.3,0.5,0.5,0.8,0.8,1.1l1.8-1l-0.8-1.5L98.2,29.3z
			M99.9,40.7l-1-3l5.4-1.7l1,3l-0.3,0.1l-0.8-2.6l-2.1,0.7l0.8,2.4l-0.3,0.1l-0.8-2.4L99.4,38l0.8,2.6L99.9,40.7z M101.6,52.5
			l5.7-0.1l0,0.4l-5.7,0.1L101.6,52.5z M102.4,62.1c-0.5-0.1-0.8-0.3-1.1-0.7c-0.2-0.4-0.3-0.9-0.2-1.4c0.1-0.7,0.2-1.2,0.4-1.6
			l0.4,0.1c-0.2,0.4-0.4,0.9-0.5,1.5c-0.1,0.5,0,0.8,0.2,1.1s0.4,0.5,0.8,0.5c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.2,0.4-0.4
			c0.1-0.2,0.3-0.5,0.5-0.8c0.3-0.6,0.5-0.9,0.8-1.1c0.3-0.2,0.6-0.3,1-0.2c0.4,0.1,0.7,0.3,1,0.6c0.2,0.4,0.3,0.8,0.2,1.3
			c-0.1,0.5-0.2,1-0.5,1.5l-0.3-0.2c0.3-0.4,0.4-0.9,0.5-1.3c0.1-0.4,0-0.8-0.2-1c-0.2-0.3-0.4-0.4-0.7-0.5c-0.2,0-0.4,0-0.5,0
			s-0.3,0.2-0.4,0.3c-0.1,0.2-0.3,0.4-0.5,0.8c-0.2,0.4-0.4,0.7-0.6,0.9s-0.4,0.3-0.6,0.4C102.9,62.1,102.7,62.1,102.4,62.1z
			M96.9,74.5l0.2-0.4l4.8,2.3l0.8-1.7L103,75l-1.8,3.7l-0.3-0.2l0.8-1.7L96.9,74.5z M91.6,83.1l1.8-2.5l4.6,3.3l-1.8,2.5L96,86.2
			l1.6-2.2l-1.8-1.3l-1.5,2.1L94,84.6l1.5-2.1l-2-1.4l-1.6,2.2L91.6,83.1z M86.2,89l5.1,2.4l0,0c-0.2-0.2-0.5-0.5-0.7-0.7l-2.9-3.1
			l0.3-0.3l3.9,4.2l-0.5,0.4l-4.9-2.3l0,0l1.9,5.1l-0.4,0.4l-3.9-4.2l0.3-0.3l2.9,3.2c0.2,0.2,0.4,0.5,0.6,0.7l0,0l-2-5.3L86.2,89z
			M78.6,99.5c-0.3-0.5-0.3-0.9-0.2-1.4c0.2-0.4,0.6-0.8,1.1-1.2l0.8-0.5l-1.2-2l0.3-0.2l2.9,4.9l-1.2,0.7
			C80,100.6,79.1,100.4,78.6,99.5z M80.5,96.8l-0.7,0.4c-0.6,0.3-0.9,0.7-1,1s-0.1,0.7,0.2,1.1c0.2,0.4,0.5,0.6,0.8,0.6
			c0.3,0,0.8-0.1,1.2-0.4l0.8-0.5L80.5,96.8z M68,102.6c-0.3-0.9-0.3-1.6-0.1-2.3c0.3-0.6,0.8-1.1,1.5-1.4c0.8-0.3,1.4-0.2,2,0.1
			c0.6,0.3,1.1,1,1.4,1.8c0.3,0.9,0.3,1.6,0.1,2.3c-0.3,0.6-0.8,1.1-1.5,1.4c-0.8,0.3-1.4,0.2-2-0.1C68.7,104.1,68.3,103.5,68,102.6z
			M72.4,101.1c-0.3-0.8-0.6-1.3-1.1-1.6c-0.5-0.3-1.1-0.3-1.7-0.1c-0.6,0.2-1.1,0.6-1.3,1.1c-0.2,0.5-0.2,1.2,0.1,2
			c0.3,0.8,0.7,1.3,1.1,1.6s1.1,0.3,1.7,0.1c0.6-0.2,1.1-0.6,1.3-1.1C72.7,102.5,72.6,101.9,72.4,101.1z M61.6,103.6l-0.3-2.5l0.4-0.1
			l0.8,5.7L61,107c-0.7,0.1-1.2,0-1.6-0.2s-0.6-0.6-0.7-1.1c-0.1-0.4,0-0.7,0.2-1c0.2-0.3,0.4-0.5,0.8-0.7l-1.9-2.4l0.5-0.1l1.8,2.3
			L61.6,103.6z M61.6,104l-1.1,0.1c-0.5,0.1-0.8,0.2-1.1,0.5c-0.2,0.2-0.3,0.6-0.3,1c0.1,0.4,0.2,0.7,0.5,0.9c0.3,0.2,0.7,0.2,1.3,0.1
			l0.9-0.1L61.6,104z M49.8,103.6l2.3,0.1l0.9-2l0.4,0l-2.6,5.6l-0.3,0l-2-5.8l0.4,0L49.8,103.6z M52,104.1l-2-0.1l0.7,2.1
			c0.1,0.2,0.1,0.4,0.2,0.7c0.1-0.2,0.2-0.5,0.3-0.7L52,104.1z M42.7,103.1l0.6-2.4l0.4,0.1l-1.4,5.5L41,106c-0.7-0.2-1.2-0.4-1.4-0.8
			s-0.3-0.8-0.2-1.3c0.1-0.4,0.3-0.7,0.5-0.9c0.3-0.2,0.6-0.3,1-0.3l-0.9-2.9l0.5,0.1l0.8,2.8L42.7,103.1z M42.7,103.4l-1.1-0.3
			c-0.5-0.1-0.9-0.1-1.2,0c-0.3,0.1-0.5,0.4-0.6,0.8c-0.1,0.4-0.1,0.8,0.2,1s0.6,0.5,1.2,0.6l0.9,0.2L42.7,103.4z M32.9,99.9l-2.8,2.2
			l-0.4-0.2l3.2-2.4l0.9-2l0.4,0.2l-0.9,2l0.3,4l-0.4-0.2L32.9,99.9z M24.2,93.6c0.3-0.4,0.6-0.6,1.1-0.6c0.4,0,0.9,0.1,1.4,0.4
			c0.6,0.4,1,0.8,1.2,1.1l-0.2,0.3c-0.2-0.4-0.6-0.7-1.2-1.1c-0.4-0.3-0.8-0.4-1.1-0.4c-0.3,0-0.6,0.2-0.8,0.5
			c-0.1,0.2-0.2,0.4-0.2,0.5s0,0.4,0.1,0.6c0.1,0.2,0.3,0.5,0.5,0.8c0.4,0.5,0.6,0.9,0.6,1.2c0.1,0.3,0,0.6-0.3,1
			c-0.2,0.4-0.6,0.5-1,0.6c-0.4,0-0.9-0.1-1.3-0.4c-0.4-0.3-0.8-0.7-1.1-1.1l0.3-0.2c0.3,0.4,0.6,0.8,1,1c0.3,0.2,0.7,0.3,1,0.3
			c0.3,0,0.6-0.2,0.8-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2,0-0.3-0.1-0.5c-0.1-0.2-0.3-0.5-0.5-0.8c-0.3-0.4-0.5-0.7-0.6-0.9
			C24,94.7,24,94.4,24,94.2C24,94,24.1,93.8,24.2,93.6z M20.8,89.2c-0.2,0.2-0.3,0.2-0.5,0.1c-0.2-0.1-0.1-0.3,0-0.5
			c0.2-0.2,0.3-0.2,0.5-0.1C21,88.8,21,89,20.8,89.2z M13.9,80.8l0.2,0.3l-2.8,3.6c-0.2,0.3-0.4,0.5-0.6,0.7c0.2-0.1,0.5-0.2,0.7-0.3
			c0.3-0.1,1.7-0.7,4.4-1.7l0.2,0.3l-3.7,4.7L12.1,88l2.4-3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3
			c0.1-0.1,0.1-0.2,0.2-0.2c-0.3,0.2-0.8,0.4-1.5,0.6l-3.4,1.3l-0.3-0.4l2.5-3.3c0.3-0.4,0.5-0.7,0.7-0.9c-0.2,0.1-0.4,0.2-0.6,0.2
			s-1.6,0.6-4.3,1.7l-0.2-0.3L13.9,80.8z M7.5,73.2l1,2.1l2.2-0.1l0.2,0.4l-6.2,0.3l-0.1-0.2l4.3-4.4L9,71.7L7.5,73.2z M8.1,75.4
			l-0.8-1.9l-1.6,1.6c-0.1,0.1-0.3,0.3-0.5,0.5c0.3,0,0.5-0.1,0.7-0.1L8.1,75.4z M7.3,66.4l-5.5,1.5l-0.1-0.4l5.5-1.5L7.3,66.4z
			M5.9,58.8l0,0.4l-5.3,0.6l0.2,1.8l-0.4,0L0,57.6l0.4,0l0.2,1.8L5.9,58.8z M6.4,45.1l-0.1,0.4l-5.6-1.1l0.6-3.1l0.4,0.1l-0.5,2.7
			l2.4,0.5L4,42l0.4,0.1l-0.5,2.5L6.4,45.1z M7.5,32c0.8,0.3,1.4,0.8,1.7,1.4c0.3,0.6,0.3,1.3,0,2c-0.3,0.7-0.8,1.2-1.4,1.4
			c-0.7,0.2-1.4,0.2-2.3-0.2c-0.8-0.3-1.4-0.8-1.7-1.5s-0.3-1.3,0-2c0.3-0.7,0.8-1.2,1.4-1.4C6,31.6,6.7,31.7,7.5,32z M5.8,36.3
			c0.8,0.3,1.4,0.4,2,0.2s1-0.6,1.2-1.2c0.3-0.6,0.3-1.2,0-1.7c-0.3-0.5-0.8-0.9-1.6-1.2c-0.8-0.3-1.4-0.4-2-0.2
			c-0.5,0.2-0.9,0.6-1.2,1.2C4,34,4,34.6,4.2,35.1C4.5,35.6,5,36,5.8,36.3z M11,26.6l2.1,1.4l-0.2,0.3l-4.7-3.2L8.9,24
			c0.4-0.6,0.8-0.9,1.2-1.1c0.4-0.1,0.8-0.1,1.3,0.2c0.3,0.2,0.5,0.5,0.6,0.8s0.1,0.7,0,1.1l3,0.2l-0.3,0.4l-2.9-0.2L11,26.6z
			M10.8,26.4l0.6-0.9c0.3-0.4,0.4-0.8,0.4-1.1c0-0.3-0.2-0.6-0.6-0.9c-0.4-0.2-0.7-0.3-1-0.2c-0.3,0.1-0.6,0.4-1,0.9L8.7,25
			L10.8,26.4z M22.8,9.7l2.2,2.9c0.4,0.5,0.6,1,0.5,1.6s-0.4,1-0.9,1.4c-0.5,0.4-1,0.6-1.6,0.5c-0.5-0.1-1-0.4-1.4-0.9l-2.2-2.9
			l0.3-0.2L22,15c0.3,0.4,0.7,0.7,1.1,0.7s0.9-0.1,1.3-0.4c0.4-0.3,0.7-0.7,0.7-1.1c0.1-0.4-0.1-0.8-0.4-1.3l-2.3-3L22.8,9.7z
			M33.4,8.5c0.2,0.4,0.2,0.8,0,1.2c-0.2,0.4-0.5,0.7-1.1,1c-0.6,0.3-1.1,0.5-1.5,0.5l-0.2-0.4c0.4,0,1-0.2,1.5-0.5
			c0.4-0.2,0.7-0.5,0.9-0.8c0.2-0.3,0.2-0.6,0-0.9c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.6-0.2s-0.6,0-1,0.1
			c-0.6,0.1-1.1,0.1-1.4-0.1S29.2,7.8,29,7.5c-0.2-0.4-0.2-0.8,0-1.2c0.2-0.4,0.5-0.7,1-0.9C30.5,5.2,31,5,31.5,5l0,0.4
			c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.6,0.4-0.8,0.7c-0.1,0.3-0.1,0.6,0,0.9c0.1,0.2,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.1,0.5,0.2s0.5,0,1,0
			c0.5-0.1,0.8-0.1,1.1,0S32.8,7.9,33,8C33.2,8.1,33.3,8.3,33.4,8.5z M38.9,7.7c-0.1-0.2,0-0.4,0.2-0.5c0.2-0.1,0.3,0,0.4,0.3
			c0.1,0.2,0,0.4-0.2,0.5C39.2,8,39,8,38.9,7.7z"/>
		</svg>);
	}
}
export default Waiting;
