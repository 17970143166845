import React,{Component} from "react";

import { TweenMax ,Linear} from "gsap/all";

class InkyLogo extends Component{

	componentDidMount() {
		TweenMax.fromTo('#InkyLab', 2,{x:-50,y:-50},{x:50,y:50})
	}

	render(){
		return (<svg id="InkyLab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 423 178"  className={this.props.className}>
		<path fill="#FF3333" d="M271.5,111.8h33.4v6.4h-41V61.4h7.6V111.8z M341.9,61.4l24.4,56.8h-8.1l-7.1-16.7H326l-7,16.7h-8l24.5-56.8
            H341.9z M349.5,96l-10.8-26.3L327.6,96H349.5z M420.3,103.6c0,2.2-0.5,4.2-1.4,6c-0.9,1.8-2.2,3.4-3.8,4.6c-1.6,1.3-3.5,2.3-5.6,3
            c-2.1,0.7-4.4,1-6.8,1h-28.4V61.4h29.8c2.1,0,4,0.4,5.6,1.3c1.7,0.9,3.1,2,4.3,3.3c1.2,1.4,2.1,2.9,2.7,4.6c0.6,1.7,1,3.5,1,5.2
            c0,2.8-0.7,5.4-2.2,7.8c-1.5,2.4-3.6,4.2-6.4,5.4c3.4,1,6.1,2.7,8.1,5.3C419.3,97,420.3,100,420.3,103.6z M381.9,86.6h19
            c1.3,0,2.6-0.3,3.7-0.8c1.1-0.5,2.1-1.2,2.9-2c0.8-0.9,1.5-1.9,1.9-3c0.5-1.1,0.7-2.4,0.7-3.6c0-1.3-0.2-2.6-0.7-3.8
            c-0.5-1.2-1.1-2.2-1.8-3c-0.8-0.9-1.7-1.5-2.8-2c-1.1-0.5-2.3-0.7-3.5-0.7h-19.4V86.6z M412.6,102.3c0-1.3-0.2-2.5-0.7-3.7
            c-0.5-1.2-1.1-2.3-1.9-3.2c-0.8-0.9-1.8-1.6-2.9-2.2c-1.1-0.5-2.4-0.8-3.7-0.8H382V112h20.8c1.4,0,2.7-0.3,3.9-0.8s2.2-1.2,3.1-2.1
            c0.9-0.9,1.6-1.9,2.1-3.1S412.6,103.6,412.6,102.3z"/>
        <path d="M77.4,61.4h3v56.8h-3V61.4z M140.7,113.8l-44-52.4h-2.2v56.9h2.9V66.7l43.3,51.5h2.9V61.4h-2.9V113.8z M200.8,61.4h-3.6
            l-36.6,35.8V61.4h-2.9v56.8v0.1h2.9v-17.6l13-12.7l24.9,30.2h3.5l-26.4-31.9L200.8,61.4z M251.7,61.4l-22.2,32.3l-22-32.3h-3.4
            L228,96.3v21.9h3V96.4l23.9-35H251.7z M63.6,61.4v56.3c0,0.2,0.1,0.4,0,0.5c-0.1,0.2-0.3,0.4-0.6,0.5L6.6,175.2
            c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.5-0.4-0.5-0.7v-56.7v-0.1V61.3c0-0.2-0.1-0.4,0-0.5
            c0.1-0.2,0.2-0.4,0.5-0.5l28.1-28.1l0.1-0.1L62.2,3.8c0.2-0.2,0.6-0.3,0.9-0.2c0.3,0.1,0.5,0.4,0.5,0.7v56.6
            C63.6,61.1,63.7,61.2,63.6,61.4z M8,117h52.7L34.4,90.7L8,117z M60.8,61.9H7.9l26.5,26.4L60.8,61.9z M6.8,116l26.5-26.5L6.8,63.1
            V116z M62,63L35.6,89.4L62,115.9V63z M62,6.2L35.6,32.6L62,59.1V6.2z M7.9,60.3h52.9L34.4,33.8L7.9,60.3z M60.9,118.7H6.8v54.1
            L60.9,118.7z"/>
		</svg>);
	}
}
export default InkyLogo;
