import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import $ from 'jquery';
import { TweenMax  } from "gsap/all";

ReactDOM.render(<>
                    <div className="cursor"></div>
                    <div className="cursor-follower"></div>
                    <App />
                </>,
                document.getElementById('root'));



// CURSOR
var cursor = $(".cursor"),
follower = $(".cursor-follower");

var posX = 0,
    posY = 0;

var mouseX = 0,
    mouseY = 0;
var mouseSTOPX = 0,
    mouseSTOPY = 0;

TweenMax.to({}, 0.016, {
  repeat: -1,
  onRepeat: function() {
    posX += (mouseX - posX) / 9;
    posY += (mouseY - posY) / 9;

    TweenMax.set(follower, {
        css: {
        left: posX - 12,
        top: posY - 12
        }
    });

    TweenMax.set(cursor, {
        css: {
        left: mouseX,
        top: mouseY
        }
    });
  }
});
setInterval(()=>{
    if(mouseSTOPX !== mouseX || mouseSTOPY !== mouseY){
        mouseSTOPX = mouseX;
        mouseSTOPY = mouseY;
    }else{
        TweenMax.to(follower, 5,{
            css: {
            left: 90,
            top: 20
            }
        });
    }
   
},5);
$(document).on("mousemove", function(e) {
    mouseX = e.clientX;
    mouseY = e.clientY;
});
// yellow circle
$("#root").on("mouseenter",".logo", function() {
    console.log('hover');
    cursor.addClass("active");
    follower.addClass("active");
});
$("#root").on("mouseleave",".logo", function() {
    console.log('blur');
    cursor.removeClass("active");
    follower.removeClass("active");
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
