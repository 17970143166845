import React, { Component } from 'react';
import { TweenMax,SteppedEase  } from "gsap/all";
import Waiting from "./assets/img/waiting";
import Boy from "./assets/img/boy";
import Twins from "./assets/img/twins" ;
import InkyLogo from "./assets/img/inkyLogo" ;
import ComingSoon from "./assets/img/comingsoon"
// import logo from './logo.svg';
// import test from "./assets/img/test.jpg"
import './App.scss';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      bg :"inky",
      boy : "boy --black" ,
      twins : "twins --black" ,
      animBoy : true,
      renderBoy : true,
      renderTwins : false,
      imON : false
    }
    this.startGlitch = this.startGlitch.bind(this);
  }


  componentDidMount(){
    //bg anim
    TweenMax.to('.inky', .03, {repeat:-1, onRepeat: ()=>{
      TweenMax.set('.inky', {backgroundPosition: Math.floor(Math.random() * 100) + 1 + "% " + Math.floor(Math.random() * 10) + 1 + "%"});
    },	ease:SteppedEase.config(1)});
  }
  startGlitch(){
    this.setState({animBoy : false});
    let frame = 0;
    let introTimer = setInterval(() => {
      switch(frame){
        case 0:
            this.setState({
              bg :"inky --dark",
              boy : "boy --white" ,
            });
            break;
        case 1:
            this.setState({
              bg :"inky",
              renderTwins : true,
              renderBoy : false,
              twins : "twins --black" ,
            });
            break;
        case 2:
            this.setState({
              bg :"inky --dark",
              twins : "twins --white" ,
            });
            break;
        case 3:
            this.setState({
              renderTwins : false,
              renderBoy : true,
              boy : "boy --white" ,
            });
            break;
        case 4:
            this.setState({
              renderTwins : true,
              renderBoy : false,
            });
            break;
        case 5:
            this.setState({
              bg :"inky",
              twins : "twins --black" ,
            });
            break;
        case 6:
            this.setState({
              bg :"inky --dark",
              twins : "twins --white" ,
            });
            break;
        case 7:
            this.setState({
              bg :"inky",
              twins : "twins --black" ,
            });
            break;
        case 8:
            this.setState({
              bg :"inky --dark",
              twins : "twins --white" ,
            });
            break;
        case 9:
            this.setState({
              bg :"inky",
              twins : "twins --black --active" ,
            });
            break;
        default:
            this.setState({
              imON : true
            },clearTimeout(introTimer))
      }
      frame++;
    }, frame < 5 ? 200 : 100 - frame * 10);
  }
  render() {
    return(
      <div className={this.state.bg}>
        {this.state.renderBoy && 
          <Boy className={this.state.boy} play={this.state.animBoy} onComplete={this.startGlitch}/>
        }
        {this.state.imON &&
        <>
          <Waiting className="waiting"/> 
          <InkyLogo className="logo"/> 
          <ComingSoon className="coming-soon"/>
        </>} 
        { this.state.renderTwins && 
          <Twins className={this.state.twins} />
        }
      </div>
    )
  }
}

export default App;
